.payment-modal {
  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    color: $gray-color;
    z-index: 10;
  }
  .payment-methods {
    img {
      width: 40px;
    }
  }
}