/**
 * Basic typography style for text
 */

body {
  font-family: $mainFont;
}

.text-dark {
  color: $darkBlue !important;
}

.text-blue {
  color: $lightBlue;
}

.text-light-blue {
  color: $faded-blue-color;
}

.text-gray {
  color: $gray-color;
}

.text-space-gray {
  color: $space-gray-color;
}

.text-red {
  color: $red-color;
}

.text-dark-gray {
  color: $dark-silver-color;
}

.text-green {
  color: $green-color;
}

.text-orange {
  color: $space-orange-color;
}

.text-mid-black {
  color: $mid-black-color;
}

.text-underline {
  text-decoration: underline;
}

.text-offwhite {
  color: $offWhite;
}

.text-yellow {
  color: $darkYellow;
}