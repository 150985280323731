.listing-card {
  .title {
    width: 95%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .img-wrapper {
    position: relative;
    height: 225px;
    border-radius: 0px;
    img {
      width: 100%;
      height: 225px;
      object-fit: cover;
      object-position: center center;
      border-radius: 0px;
    }
    .icon-wrapper {
      position: absolute;
      top: 15px;
      right: 15px;
      background-color: rgba(0, 0, 0, 0.7);
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      z-index: 11;
    }
  }
  .star-ratings {
    margin-top: -6px;
  }
}