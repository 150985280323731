.contact-section {
  padding: 5rem 0;
  .box-wrapper {
    padding: 3rem;
    border-radius: 0.3rem;
    .social-list {
      li {
        img {
          height: 28px;
        }
      }
    }
    .divider {
      height: 100%;
      border-right: 1px solid $light-gray-color;
    }
  }
}