.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $blue-color;
    border-color: $blue-color;
  }
  .custom-control-label { 
    &:focus {
      outline: 0;
    }
  }
}