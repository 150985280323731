.filters {
  min-height: 80px;
}

.map-section {
  position: relative;
  height: calc(100vh - 160px);
  .map-info {
    background-color: $white;
    left: 22px;
    padding: 15px;
    position: absolute;
    top: 60px;
    width: 45%;
    z-index: 10;
    border-radius: 3px;
  }
  .search-checkbox {
    @extend .map-button;
    left: 22px;
    padding: 0 10px;
    position: absolute;
    top: 10px;
    z-index: 10;
  }
  .info-window {
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    bottom: 30px;
    width: 65%;
    height: 150px;
    border-radius: 0px;
    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      object-position: center center;
    }
  }
}

.scrollable {
  height: calc(100vh - 160px);
  max-height: calc(100vh - 160px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 767px) {
  .filters {
    padding: 1rem 0;
  }
  .map-section {
    padding: 0 !important;
    .info-window {
      height: unset;
      min-height: 150px;
      border-radius: 0px;
      bottom: 50px;
      width: 90%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}