.activities-step {
  .category-container {
    background-color: $silver-color;
    .header {
      background-color: $dark-color;
      min-height: 55px;
      h5 {
        font-weight: 700;
      }
    }
    .content-container {
      padding: 25px;
      ul {
        padding-inline-start: 20px;
      }
    }
    .options-container {
      padding: 0 25px 25px 25px;
      hr {
        border-top: 1px solid $blue-color;
      }
      .input-group {
        .input-group-text, .form-control {
          border-radius: 0;
          border: 1px solid $light-gray-color;
          background-color: $white-color;
        }
        .input-group-text {
          &:first-child {
            border-right: 0;
          }
          &:last-child {
            border-left: 0;
          }
        }
      }
    }
  }

  .add-on-container {
    padding: 35px 25px 25px 25px; 
    width: 100%;
    min-height: 232px;
    border-radius: 3px;
    margin-bottom: 30px;
    &.dashed {
      border: 1px dashed $dark-color;
    }
    &.light {
      background-color: $silver-color;
    }
  }
}