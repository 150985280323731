// -----------------------------------------------------------------------------
// This file contains all styles related to the input component.
// -----------------------------------------------------------------------------

input.form-control:focus,
input.form-control:hover {
  outline: 0;
  box-shadow: none;
}

.input-group-merge {
  .input-group-text {
    border: none;
    background-color: $white;
    border-radius: 3px;
    svg {
      color: $darkBlue;
    }
  }
  input {
    border: none;
    background-color: $silver-color;
    border-radius: 3px;
    padding-left: 0px;
    height: 42px;
    &:focus {
      background-color: $silver-color;
    }
    &::placeholder {
      color: $dark-gray-color;
    }
  }
}

.otp-input {
  width: 100% !important;
  .styles_react-code-input__CRulA {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    input {
      border-radius: 0px !important;
      background: transparent;
      border: none;
      border-bottom: 2px solid $sonic-silver-color;
      margin-right: 15px;
      color: $white-color;
      font-family: $mainFont;
      &:focus {
        border: none;
        border-bottom: 2px solid $white-color;
        caret-color: $white-color;
      }
    }
  }
  &.light {
    .styles_react-code-input__CRulA {
      input {
        border-radius: 4px !important;
        background: $white-color;
        border: 1px solid $light-gray-color;
        margin-right: 15px;
        color: $gray-color;
        font-family: $mainFont;
        &:focus {
          border: 1px solid $blue-color;
          caret-color: $gray-color;
        }
      }
    }
  }
}

.react-select.is-invalid {
  border: 1px solid $red-color;
  border-radius: 3px;
}

@media only screen and (max-width: 600px) {
  .input-group-merge {
    margin-bottom: 20px;
  }
}
