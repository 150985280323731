.header-container {
  min-height: 400px;
  padding: 0 25px;
  background-color: $dark-silver-color;
}

.listings-section {
  .listing-container {
    height: 350px;
    background-color: $light-gray-color;
  }
}

.logos-section {
  background-color: $silver-color;
  .logo-container {
    background-color: $gray-color;
    min-height: 80px;
  }
}
