.calendar-wrapper {
  .dropdown-container {
    background-color: $silver-color;
    padding: 15px 15px 10px 20px;
    .outlined-input {
      .form-control {
        border: none;
        background-color: $silver-color;
      }
      label {
        background-color: transparent;
      }
    }
  }
  .react-datepicker {
    width: 100%;
    .react-datepicker__day--outside-month {
      opacity: 0.2;
      pointer-events: none;
    }
    .react-datepicker__month-container {
      width: 100%;
      .react-datepicker__day {
        position: relative;
        &.approved {
          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 0;
            border-color: transparent $dark-color transparent transparent;
          }
        }
        &.pending {
          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            width: 7px;
            height: 7px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: $blue-color;
          }
        }
      }
    }
  }
  .days-list {
    li {
      background-color: $silver-color;
      width: calc(100% / 8.4);
      height: 33px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $gray-color;
      cursor: pointer;
      &.active {
        background-color: $dark-color;
        color: $white-color;
      }
    }
  }
  .booking-wrapper {
    border-radius: 0 4px 4px 0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);    
    margin-bottom: 25px;
    min-height: 140px;
    background-color: $white-color;
    &.left-blue-border {
      border-left: 8px solid $blue-color;
    }
    &.left-dark-border {
      border-left: 8px solid $dark-color;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
    .hr {
      border-top: 1px solid $gray-color;
      opacity: 0.3;
    }
    .status {
      .pending {
        color: $dark-color;
      }
      .confirmed {
        color: $white-color;
        background-color: $dark-color;
        padding: 0 12px;
      }
      .declined {
        color: $white-color;
        background-color: $red-color;
        padding: 0 12px;
      }
      .draft {
        color: $orange-color;
      }
    }
  }
  .blue-circle {
    width: 7px;
    height: 7px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: $blue-color;
  }
  .dark-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent $dark-color transparent transparent;
  }
}