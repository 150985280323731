// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
 html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: none; 
}

#root,
html,
body {
  height: 100%;
}

/**
 * Basic styles for links
 */
 a {
  text-decoration: none;
  color: $blue-color;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}