// -----------------------------------------------------------------------------
// This file contains all styles related to the range slider component.
// -----------------------------------------------------------------------------

.rc-slider {
  .rc-slider-rail {
    background-color: $faded-silver-color;
    border: 1px solid $platinum-color;
    height: 5px;
  }
  .rc-slider-track {
    background-color: $blue-color;
  }
  .rc-slider-handle {
    border: 2px solid $sonic-silver-color;
    box-shadow: 0 1px 2px 0 $dark-color;
  }
}