div.react-datepicker {
  font-family: $mainFont;
  border-color: $light-gray-color;
  .react-datepicker__navigation {
    top: 7px;
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: transparent;
      border-bottom: none;
      .react-datepicker__current-month {
        font-weight: normal;
      }
    }
    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today,
    .react-datepicker__year-text--today {
      color: $blue-color;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      color: $white-color;
      background-color: $blue-color;
      &:hover {
        background-color: $blue-color;
      }
    }
    .react-datepicker__day--selected,
    .react-datepicker__month-text--selected,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__year-text--selected {
      color: $white-color;
      background-color: $blue-color;
      &:hover {
        background-color: $blue-color;
      }
    }
  }
}

div.react-datepicker__time-container {
  .react-datepicker__header--time.react-datepicker__header--time--only {
    display: none;
    border-top-right-radius: 0.3rem;
  }
  .react-datepicker__time {
    border-top-left-radius: 0.3rem;
    .react-datepicker__time-box ul.react-datepicker__time-list {
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px $gray-color;
        -webkit-box-shadow: inset 0 0 6px $gray-color;
        background-color: $light-gray-color;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: $light-gray-color;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $gray-color;
      }

      li.react-datepicker__time-list-item {
        height: 17px;
      }
    }
  }
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  right: 0;
  left: unset;
  border-bottom-color: $faded-silver-color;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  color: $white-color !important;
  background-color: $blue-color;
}

.inline-datepicker {
  .react-datepicker {
    width: 100%;
    .react-datepicker__month-container {
      width: 100%;
    }
  }
}