// -----------------------------------------------------------------------------
// This file contains all styles related to the tabs component.
// -----------------------------------------------------------------------------

.underlined-tabs {
  .nav-item {
    cursor: pointer;
    .nav-link {
      color: $dark-color;
      &.active {
        color: $blue-color;
        text-decoration: underline;
      }
    }
    &:first-child {
      .nav-link {
        padding-left: 0;
      }
    }
  }
}