.profile-settings {
  .avatar-img {
    height: 90px;
    width: 90px;
    object-fit: cover;
    border-radius: 50%;
  }
  .input-icon {
    position: absolute;
    top: 72%;
    transform: translateY(-72%);
    right: 12px;
  }
  .img-preview {
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
