// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
[role="button"]:focus {
  outline: 0;
  box-shadow: none;
}

.btn.btn-link {
  text-decoration: none;
}

.blue-btn {
  background-color: $darkBlue;
  color: $white-color;
  border-radius: 3px;
  height: 42px;
  &:hover {
    background-color: $white;
    color: $darkBlue;
    border: 1px solid $darkBlue;
    transition: ease-in-out 0.3s;
  }
  &.br-0 {
    border-radius: 0px;
  }
}

.blue-outline-btn {
  background-color: $silver-color;
  border: 1px solid $blue-color;
  color: $blue-color;
  border-radius: 3px;
  height: 42px;
  &:hover {
    background-color: $blue-color;
    color: $white-color;
    border: 1px solid $blue-color;
    transition: ease-in-out 0.3s;
  }
  &.br-0 {
    border-radius: 0px;
  }
}

.white-btn {
  background-color: $white-color;
  color: $blue-color;
  border-radius: 3px;
  height: 42px;
  &:hover {
    background-color: $white-color;
    color: $blue-color;
    transition: ease-in-out 0.3s;
  }
}

.light-btn {
  background-color: $light-gray-color;
  color: $dark-color;
  border: 1px solid $light-gray-color;
  border-radius: 3px;
  height: 42px;
  &:hover {
    background-color: $dark-color;
    border: 1px solid $dark-color;
    color: $light-gray-color;
    transition: ease-in-out 0.3s;
  }
  &.br-0 {
    border-radius: 0px;
  }
}

.silver-btn {
  background-color: $silver-color;
  color: $dark-color;
  border: 1px solid $silver-color;
  border-radius: 3px;
  height: 50px;
  &:hover {
    background-color: $dark-color;
    border: 1px solid $dark-color;
    color: $silver-color;
    transition: ease-in-out 0.3s;
  }
  &.br-0 {
    border-radius: 0px;
  }
}

.black-btn {
  background-color: $dark-color;
  color: $white-color;
  border-radius: 3px;
  height: 42px;
  &:hover {
    background-color: $white-color;
    color: $dark-color;
    border: 1px solid $dark-color;
    transition: ease-in-out 0.3s;
  }
  &.br-0 {
    border-radius: 0px;
  }
}

.red-btn {
  background-color: $red-color;
  color: $white-color;
  border-radius: 3px;
  height: 42px;
  &:hover {
    background-color: $white-color;
    color: $red-color;
    border: 1px solid $red-color;
    transition: ease-in-out 0.3s;
  }
  &.br-0 {
    border-radius: 0px;
  }
}

.crimson-btn {
  background-color: $crimson-color;
  color: $white-color;
  border-radius: 3px;
  height: 42px;
  &:hover {
    background-color: $white-color;
    color: $crimson-color;
    border: 1px solid $crimson-color;
    transition: ease-in-out 0.3s;
  }
}

.tweet-btn {
  background-color: $white-color;
  color: $twitter-blue-color;
  border: 1px solid $twitter-blue-color;
  border-radius: 3px;
  height: 42px;
  &:hover {
    background-color: $twitter-blue-color;
    color: $white-color;
    border: 1px solid $twitter-blue-color;
    transition: ease-in-out 0.3s;
  }
}

.gray-btn {
  background-color: $dark-silver-color;
  color: $white-color;
  border-radius: 3px;
  height: 50px;
  &:hover {
    background-color: $dark-silver-color;
    color: $white-color;
    transition: ease-in-out 0.3s;
  }
}

.icon-btn {
  background-color: $light-gray-color;
  border: 1px solid $faded-silver-color;
  height: 42px;
  &:hover {
    background-color: $light-gray-color;
    transition: ease-in-out 0.3s;
  }
}

.btn-group {
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    background-color: $darkBlue;
    border-color: $darkBlue;
  }
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }
  .btn-secondary {
    color: rgba(0, 0, 0, 0.25);
    background-color: $white;
    border-color: rgba(0, 0, 0, 0.15);
    min-width: 70px;
    font-size: 14px;
    &.active {
      color: $white;
    }
  }
}




.yellow-btn {
  background-color: $darkYellow;
  color: $darkBlue;
  height: 42px;
  &:hover {
    background-color: $darkYellow;
    color: $darkBlue;
  }
}