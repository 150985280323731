.chat-container {
  position: relative;
  height: 80vh;
  background-color: $white-color;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  padding: 10px 0 0 0;
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .messages {
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 28px 45px 0 45px;
    @media (max-width: 767px) {
      padding: 10px;
    }
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
  .chat-box {
    width: 90%;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}

.status-alert {
  min-height: 40px;
  img {
    height: 20px;
    width: 20px;
  }
  &.orange-bg {
    background-color: $light-orange-color;
    border-radius: 4px;
    padding: 0 25px;
  }
  &.red-bg {
    background-color: $light-red-color;
    border-radius: 4px;
    padding: 0 25px;
  }
  &.platinum-bg {
    background-color: rgba($platinum-color, 0.45);
    padding: 15px 25px;
  }
}

.booking-details {
  .cover-img {
    .status {
      position: absolute;
      top: 20px;
      left: 15px;
      min-width: 100px;
      padding: 3px 15px;
      border-radius: 3px;
      color: $white-color;
      &.orange-badge {
        background-image: linear-gradient(77deg, #ff8d2a 6%, #fa7129);
      }
      &.dark-badge {
        background-color: $dark-color;
      }
      &.red-badge {
        background-color: $dark-orange-color;
      }
      &.gray-badge {
        background-color: $gray-color;
      }
      &.white-badge {
        background-color: $white-color;
        color: $dark-color;
      }
    }
    .host-info {
      position: absolute;
      top: 20px;
      right: 15px;
      img {
        height: 50px;
        width: 50px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}

.cancel-modal {
  max-width: 600px;
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $dark-color;
    z-index: 10;
  }
  .modal-content {
    background-color: $silver-color;
    .icon {
      width: 40px;
      height: 40px;
    }
  }
}

.reviews-modal {
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $dark-color;
    z-index: 10;
  }
  .modal-content {
    background-color: $silver-color;
  }
  &.md-width {
    max-width: 600px;
  }
}
