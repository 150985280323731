.map-button {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
}
.current-location {
  @extend .map-button;
  width: 40px;
  position: absolute;
  right: 11px;
  bottom: 180px;

  img {
    height: 30px;
    width: 30px;
  }
}



.black-tooltip {
  // Use `!important` flag to override inline styles
  .gm-style-iw-c {
    background-color: $dark-color !important;
    color: $white-color !important;
    padding: 2px 8px !important;
    border-radius: 2px !important;

    div.gm-style-iw-d {
      overflow: auto !important;
      span {
        vertical-align: middle;
        &.price {
          margin-right: 3px;
        }
        &.per-hour {
          font-size: $medium-font;
        }
      }
    }

    button.gm-ui-hover-effect {
      display: none !important;
    }
  }
  
  .gm-style-iw-t::after {
    background: $dark-color !important;
    height: 5px !important;
    width: 5px !important;
  }
}