.host-dashboard {
  .inbox-list {
    .badge {
      min-width: 100px;
      height: 34px;
      padding: 0 15px;
      border-radius: 3px;
      color: $dark-color;
      display: flex;
      align-items: center;
      justify-content: center;
      &.light-badge {
        background-color: $faded-silver-color;
      }
      &.blue-badge {
        background-color: $blue-color;
      }
      &.sm {
        height: 24px;
        padding: 0;
      }
    }
    hr {
      border-top: 1px solid $gray-color;
      opacity: 0.3;
    }
    .inbox-card {
      background-color: $white-color;
      border-radius: 4px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 25px;
      border-left: 7px solid $blue-color;
      .img-container {
        height: 100%;
        width: 100%;
        background-color: $faded-baby-blue-color;
        padding: 10px;
        h6 {
          font-size: 15px;
        }
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .card-content {
        padding: 30px 40px 30px 15px;
      }
      .status {
        padding: 3px 20px;
        border-radius: 3px;
        &.waiting {
          color: $white-color;
          color: $space-orange-color;
          padding: 0;
        }
        &.confirmed {
          color: $white-color;
          background-color: $dark-color;
        }
        &.declined {
          color: $white-color;
          background-color: $dark-orange-color;
        }
        &.canceled {
          color: $white-color;
          background-color: $gray-color;
        }
        &.completed {
          color: $dark-color;
          background-color: $light-silver-color;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .host-dashboard {
    .inbox-list {
      .inbox-card {
        border-right: 7px solid $blue-color;
        border-left: none;
        .card-content {
          padding: 30px 15px 30px 40px;
        }
      }
    }
  }
}