.profile-section {
  .user-details {
    background-image: linear-gradient(to bottom right, $dark-color, #393939);
    padding: 2rem 0 0 0;
    .avatar-img {
      height: 100px;
      width: 100px;
      object-fit: cover;
      border-radius: 50%;
    }
    .gray-btn {
      min-width: 145px;
      &.inactive {
        opacity: 0.4;
      }
    }
    .sidebar-footer {
      margin-top: 25rem;
      .links-list {
        li {
          &::after {
            content: "\00B7";
            padding-left: 5px;
            color: $dark-gray-color;
          }
          &:last-child {
            &::after {
              content: "";
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  .listing-card {
    margin-bottom: 40px;
    .img-container {
      width: 100%;
      height: 230px;
      position: relative;
      img {
        width: 100%;
        height: 230px;
        object-fit: cover;
      }
      .price-tag {
        position: absolute;
        right: 20px;
        bottom: 20px;
        background-color: $white-color;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        font-size: 14px;
        padding: 5px 10px;
        span {
          font-size: 17px;
          font-weight: bold;
        }
      }
    }
    .title {
      width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .star-ratings {
      margin-left: 0;
      margin-top: -5px;
    }
  }
  .review-card {
    padding: 30px 35px 30px 54px;
    background-color: $silver-color;
    img {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }
}

@media (max-width: 767px) {
  .profile-section {
    .user-details {
      height: unset;
      padding: 2rem;
      .sidebar-footer {
        margin-top: 5rem;
      }
    }
  }
}