// -----------------------------------------------------------------------------
// This file contains styles that are specific to the listing details page.
// -----------------------------------------------------------------------------

.info-container {
  &.append-dot {
    &::after {
      content: '\00B7';
      padding-left: 25px;
      color: $dark-gray-color;
    }
    @media (max-width: 767px) {
      &::after {
        content: '';
        padding-left: 0;
      }
    }
  }
  img {
    height: 15px;
    margin-inline-end: 8px;
  }
}

.images-grid {
  img {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    object-fit: cover;
  }
  .main-image {
    height: 410px;
    object-fit: cover;
  }
}

.review-card {
  margin-top: 15px;
  .user-avatar {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
}

.booking-sticky-wrapper {
  position: sticky;
  top: 25px;
  @media (max-width: 767px) {
    position: static;
  }
}

.date-container {
  background-color: $white-color;
  border-radius: 4px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.07);
  min-height: 45px;
  padding: 10px 15px 5px 15px;
  position: relative;
  .icon {
    position: absolute;
    right: 10px;
    top: 14px;
  }
}

.note-container {
  margin-top: 15px;
  padding: 10px 15px;
  background-image: linear-gradient(to left, #393939 100%, #191919 0%);
}

.images-modal {
  position: relative;
  .modal-caption {
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .carousel {
    img {
      width: 100%;
      height: 520px;
      object-fit: cover;
    }
  }
}

.share-modal {
  .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $gray-color;
    z-index: 10;
  }
}
