
.time-input {
	position: relative;

	.clock-wrapper {
		display: none;
		position: absolute;
		left: 0;
		z-index: 1;
		text-align: initial;

		&.focused {
			display: block;
		}
	}
	.form-control:disabled, .form-control[readonly] {
		background-color: $white-color;
	}
}
