.sidenavs {
  border-inline-end: solid 1px $light-gray-color;
  min-height: 500px;
  .navs-list {
    margin-bottom: 0;
    padding-top: 1rem;
    li {
      height: 40px;
      box-sizing: border-box;
      margin-bottom: 0.8rem;
      img {
        height: 25px;
        width: 25px;
        object-fit: contain;
      }
      a {
        color: $dark-color;
        display: flex;
        align-items: center;
        height: 40px;
        border-left: 4px solid $white-color;
        padding: 0.5em 0 0.5rem 1rem;
        h6 {
          font-weight: 600;
          margin-left: 0.8rem;
          cursor: pointer;
        }
        &:hover {
          border-left: 4px solid $blue-color;
          transition: border-left ease-in 0.2s;
        }
        &.active {
          border-left: 4px solid $blue-color;
        }
      }
      &:last-child {
        margin-bottom: 4rem;
      }
    }
  }
  .navs-footer {
    padding: 0 0 0 1rem;
    .links-list {
      li {
        &::after {
          content: "\00B7";
          padding-left: 5px;
          color: $dark-gray-color;
        }
        &:last-child {
          &::after {
            content: "";
            padding-left: 0;
          }
        }
      }
    }
  }
}

html[dir="rtl"] {
  .sidenavs {
    .navs-list {
      li {
        a {
          border-right: 4px solid $white-color;
          border-left: none;
          padding: 0.5em 1rem 0.5rem 0;
          h6 {
            margin-right: 0.8rem;
            margin-left: unset;
          }
          &:hover {
            border-right: 4px solid $blue-color;
            border-left: none;
          }
          &.active {
            border-right: 4px solid $blue-color;
            border-left: none;
          }
        }
      }
    }
    .navs-footer {
      padding: 0 1rem 0 0;
      .links-list {
        li {
          &::after {
            padding-right: 5px;
            padding-left: unset;
          }
          &:last-child {
            &::after {
              padding-left: unset;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}