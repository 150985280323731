.reporting {
  .statistics-box {
    min-height: 115px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    color: $mid-black-color;
    margin-bottom: 30px;
    &.silver {
      background-color: $faded-silver-color;
    }
    &.light {
      background-color: $white-color;
    }
  }
  .table {
    border: solid 1px rgba(112, 112, 112, 0.14);
    .table-header {
      background-color: $white-color;
      th {
        padding: 0.8rem 0 0.8rem 1rem;
        border: none;
        color: $dark-color;
        font-size: 16px;
        &.expand-cell-header {
          padding: 0;
          color: $white-color;
        }
      }
    }
    .table-body {
      &.left-border {
        border-left-width: 4px;
        border-left-color: $red-color;
      }
      td {
        padding: 0.8rem 0 0.8rem 1rem;
        border: none;
        color: $mid-black-color;
        font-weight: bold;
        font-size: 16px;
        vertical-align: middle;
        &.expand-cell {
          padding: 0.8rem 0;
        }
      }
    }
    > :not(:first-child) {
      border-top: none;
    }
    .row-expansion-style {
      padding: 0;
      .expanded-row {
        padding: 15px 30px;
        background-color: $faded-silver-color;
        @media (max-width: 767px) {
          padding: 15px;
        }
      }
    }
  }
}