// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.navbar {
  background-color: $white;
  min-height: 80px;
  .navbar-brand {
    img {
      height: 45px;
    }
  }
  .navbar-nav {
    .nav-link {
      color: $darkBlue;
    }
  }
}
