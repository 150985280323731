// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  padding: 2rem 0;
  background-color: $white;
  border-top: 4px solid $darkYellow;
  hr {
    background-color: $lightYellow;
  }
}