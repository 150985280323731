.dropzone-file-uploader {
  .blue-container {
    cursor: pointer;
    margin-bottom: 15px;
    border: 1px solid $blue-color;
    background-color: $baby-blue-color;
    border-radius: 3px;
    width: 100%;
    min-height: 40px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.uploaded-file {
  background-color: $white-color;
  min-height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $blue-color;
  background-color: $baby-blue-color;
  border-radius: 3px;
  margin-bottom: 15px;
}