// -----------------------------------------------------------------------------
// This file contains all styles related to the input component.
// -----------------------------------------------------------------------------

.modal {
  .modal-content {
    .modal-header {
      padding: 0.8rem 1rem;
      .modal-title {
        font-size: 16px;
      }
    }
    .modal-footer {
      border: none;
      padding-top: 0px;
    }
  }
}

.silver-modal {
  .modal-content {
    background-color: $silver-color;
    border: none;
  }
}