.form-switch.lg {
  .form-label {
    padding-top: 0.3rem;
    margin-left: 0.3rem;
  }
  .form-check-input {
    height: 1.5rem;
    width: calc( 2rem + 0.75rem );
    border-radius: 3rem;
    &:checked {
      background-color: $darkBlue;
      border-color: $darkBlue;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.form-check {
  .form-check-input[type=checkbox] {
    border-radius: 0px;
    border: 1px solid $sonic-silver-color;
  }
  .form-check-input {
    &:checked {
      background-color: $darkBlue;
      border: 1px solid $darkBlue;
    }
  }
  &.form-switch {
    .form-check-input[type=checkbox] {
      border-radius: 2em;    
    }
  }
}