// ---------------------------------------------------------------------------
// This file contains styles that are specific to the booking request screens.
// ---------------------------------------------------------------------------

.white-container {
  background-color: $white-color;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  &.first-step, &.third-step {
    padding: 28px 45px 45px 45px;
    @media (max-width: 767px) {
      padding: 10px;
    }
    .date-container {
      background-color: $white-color;
      border-radius: 3px;
      box-shadow: 0 7px 30px 0 rgba(0, 0, 0, 0.1);
      min-height: 52px;
      position: relative;
      padding: 15px 15px 5px 15px;
      .icon {
        position: absolute;
        top: 16px;
        right: 15px;
      }
    }
    .form-control, .form-select {
      border-radius: 3px;
      padding: 0 28px 0 28px;
      border: 1px solid $light-gray-color;
      &::placeholder {
        color: $dark-color;
        opacity: 0.3;
      }
    }
    input.form-control, .form-select {
      height: 52px;
    }
    textarea.form-control {
      padding: 15px 28px 0 28px;
    }
    .segment-btns {
      .btn {
        height: 50px;
        border-radius: 3px;
        border: solid 1px $light-gray-color;
        width: 150px;
        color: $gray-color;
        &:first-child {
          background-color: $blue-color;
          color: $white-color;
        }
      }
    }
    .btn-group > .btn-group:not(:last-child) > .btn,
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
      border-radius: 3px;
    }
  }
  &.second-step {
    padding: 10px 30px 30px 30px;
    position: relative;
    .checkbox-lg {
      label {
        color: $dark-silver-color;
        margin-inline-start: 10px;
        margin: 5px 0 0 10px;
      }
      input[type="checkbox"] {
        -ms-transform: scale(1); /* IE */
        -moz-transform: scale(1); /* FF */
        -webkit-transform: scale(1); /* Safari and Chrome */
        -o-transform: scale(1); /* Opera */
        transform: scale(1);
        padding: 10px;
      }
    }
    .badge {
      font-family: $mainFont;
      font-weight: bolder;
      font-size: 0.90em;
      &.dark-badge {
        background-color: $dark-color;
        position: absolute;
        top: 25px;
        right: 30px;
        padding: 0.40em 0.90em;
      }
      &.blue-badge {
        background-color: $blue-color;
        padding: 0.40em 0.50em;
        margin-inline-start: 10px;
        margin-top: 5px;
        position: absolute;
        top: 7px;
        right: 0;
      }
    }
    p {
      padding-left: 1.9em;
      &.lg {
        font-size: 18px;
        padding-left: 1.8em;
      }
    }
  }
  &.third-step {
    padding: 28px 45px 45px 45px;
    .input-group {
      .btn {
        padding: .375rem 1rem;
        height: 52px;
      }
    }
    .payment-methods {
      li {
        img {
          height: 30px;
        }
      }
    }
    p {
      &.lg {
        font-size: 17px;
      }
    }
  }
}

.cover-img {
  height: 330px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  position: relative;
  img {
    height: 330px;
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .caption {
    width: 100%;
    background-color: rgba($dark-color, 0.5);
    padding: 15px 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    h5 {
      font-size: 1.1rem;
      margin-bottom: 5px;
    }
    h6 {
      font-size: 0.8rem;
    }
  }
}

html[dir="rtl"] {
  .white-container {
    &.second-step {
      .badge {
        &.dark-badge {
          left: 30px;
          right: unset;
        }
        &.blue-badge {
          left: 0;
          right: unset;
        }
      }
      p {
        padding-left: 0;
        padding-right: 1.9em;
        &.lg {
          padding-left: 0;
          padding-right: 1.8em;
        }
      }
    }
  }  
}