// -----------------------------------------------------------------------------
// This file contains all styles related to the form component.
// -----------------------------------------------------------------------------

.form-control:focus,
.form-control:focus,
.form-control:hover {
  outline: 0;
  box-shadow: none;
  border-color: $lightBlue;
}

.form-group {
  .form-control {
    border: 1px solid $borderColor;
    color: $darkBlue;
    &::placeholder {
      color: $borderColor;
      opacity: 1;
    }
    &:hover, &:focus {
      border: 1px solid $lightBlue;
      box-shadow: none;
      color: $darkBlue;
    }
  }
}

.react-tel-input  {
  font-family: $mainFont;
  direction: ltr;
  .form-control {
    width: 100%;
    display: block;
    width: 100%;
    color: $darkBlue;
    background-clip: padding-box;
    border: 1px solid $borderColor;
    appearance: none;
    &:focus, &:hover {
      border: 1px solid $lightBlue;
      box-shadow: none;
      color: $darkBlue;
    }
    &::placeholder {
      color: $borderColor;
    }
    &.is-invalid {
      border: 1px solid $red;
    }
  }
}

.invalid-feedback {
  color: $red;
}
