.dropzone-uploader {
  border: 1px solid $light-gray-color;
  border-radius: 5px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 25px;

  &.invalid {
    border-color: #e26551;
  }

  .img-placeholder {
    height: 70px;
    margin-bottom: 25px;
  }
  .blue-container {
    cursor: pointer;
    margin-top: 15px;
    border: 1px solid $blue-color;
    background-color: $baby-blue-color;
    border-radius: 3px;
    min-width: 500px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image-preview {
    margin-top: 30px;
    min-height: 200px;
    position: relative;
    img {
      width: 100%;
      min-height: 200px;
      object-fit: cover;
    }
    .blur-img {
      filter: blur(1.5px);
      -webkit-filter: blur(1.5px);
    }
    .image-overlay {
      opacity: 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba($dark-color, 0.6);
      color: $white-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
      &.invalid {
        opacity: 1;
        background-color: rgba($red-color, 0.5);
      }
    }
    &:hover {
      .image-overlay {
        opacity: 1;
        transition: opacity ease-in-out 0.3s;
      }
    }
    .progress{
      margin-top: -50%;
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }
  }
}