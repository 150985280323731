.booking-card {
  border-radius: 0 4px 4px 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;
  min-height: 200px;
  &.left-dark-border {
    border-left: 8px solid $dark-color !important;
  }
  &.left-orange-border {
    border-left: 8px solid $space-orange-color !important;
  }
  .listing-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  .host-info {
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }
  .content-container {
    padding: 20px 45px 20px 0;
    @media (max-width: 767px) {
      padding: 20px 45px 20px 20px;
    }
  }
  hr {
    border-top: 1.4px solid $gray-color;
    opacity: 1;
  }
  .status {
    border-radius: 3px;
    &.completed {
      background-color: $light-silver-color;
      color: $dark-color;    
      padding: 3px 20px;  
    }
    &.canceled {
      background-color: $gray-color;
      color: $white-color;
      padding: 3px 20px;
    }
    &.declined {
      background-color: $space-orange-color;
      color: $white-color;
      padding: 3px 20px;
    }
    &.in-review {
      color: $blue-color;
    }
    &.waiting {
      color: $orange-color;
    }
    &.confirmed {
      background-color: $dark-color;
      color: $white-color;
      padding: 3px 20px;
    }
  }
}

html[dir="rtl"] {
  .booking-card {
    .content-container {
      padding: 20px 0 20px 45px;
      @media (max-width: 767px) {
        padding: 20px 20px 20px 45px;
      }
    }
    hr {
      border-top: 1.4px solid $gray-color;
      opacity: 1;
    }
    .status {
      border-radius: 3px;
      &.completed {
        background-color: $light-silver-color;
        color: $dark-color;    
        padding: 3px 20px;  
      }
      &.canceled {
        background-color: $gray-color;
        color: $white-color;
        padding: 3px 20px;
      }
      &.declined {
        background-color: $space-orange-color;
        color: $white-color;
        padding: 3px 20px;
      }
      &.in-review {
        color: $blue-color;
      }
      &.waiting {
        color: $orange-color;
      }
      &.confirmed {
        background-color: $dark-color;
        color: $white-color;
        padding: 3px 20px;
      }
    }
  }
}