// -----------------------------------------------------------------------------
// This file contains styles that are specific to the login modal.
// -----------------------------------------------------------------------------

.light-modal {
  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    color: $lightBlue;
    z-index: 10;
  }
  .modal-content {
    background-color: $white;
    border-radius: 8px;
    border: none;
    .avatar {
      background-color: $darkBlue;
      width: 85px;
      height: 85px;
      border-radius: 50%;
      position: relative;
      img {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center center;
      }
      .upload-btn {
        position: absolute;
        right: 5px;
        bottom: -5px;
        background-color: $white;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 2px solid $darkBlue;
      }
    }
  }
}