input.pac-target-input {
  flex: 1;
}

.pac-container.pac-logo {
  font-family: $mainFont;
  background-color: $silver-color;
  &::after {
    display: none;
  }
  .pac-item {
    cursor: pointer;
    &:hover {
      background-color: $light-blue-color;
    }
  }
}
