/* Tabs */
.host-dashboard {
  .tabs-container {
    .pagination-container {
      .page-item {
        margin: 0 5px;
        &.active {
          .page-link {
            background-color: $blue-color;
            color: $white-color;
          }
        }
        &.disabled {
          .page-link {
            color: $gray-color;
          }
        }
      }
      .page-link {
        border: none !important;
        font-size: 1.3rem;
        width: 1.8em;
        color: $blue-color;
      }
    }
  }

  /* Listing card */
  .listing-card {
    min-height: 200px;
    background-color: $silver-color;
    margin-bottom: 25px;
    padding: 1.5rem;
    .img-container {
      position: relative;
      height: 170px;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 170px;
        object-fit: cover;
      }
      .overlay {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        bottom: 0;
        right: 0;
        left: 0;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .listing-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 170px;
      .status {
        min-height: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 15px;
        }
        &.draft {
          border: 1px solid $dark-silver-color;
          color: $dark-silver-color;
        }
        &.published {
          border: 1px solid $dark-silver-color;
          background-color: $dark-silver-color;
          color: $white-color;
        }
        &.in-review {
          border: 1px solid $orange-color;
          background-color: $orange-color;
          color: $white-color;
        }
      }
      .progress {
        height: 0.5em;
        border-radius: 0;
        .progress-bar {
          background-color: $blue-color;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .listing-card {
    .img-container {
      margin-bottom: 15px;
    }
    .listing-details {
      .btn {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
