.stepper-container {
  padding: 3rem 0 3rem 0;
  .bs-stepper {
    .bs-stepper-header {
      min-width: 300px;
      height: 100%;
      
      .step {
        position: relative;
        button.step-trigger {
          padding: 0;
          justify-content: unset;
          &:hover {
            background-color: transparent;
          }
          span.bs-stepper-circle {
            width: 13px;
            height: 13px;
            margin: 0 25px 0 5px;
            padding: 2px;
            background-color: $white-color;
            border: 2px solid $dark-gray-color;
          }
          span.bs-stepper-label {
            text-align: start;
            margin: 0;
            .desc {
              font-size: 0.8rem;
              font-weight: normal;
              position: absolute;
              line-height: 1.1;
              white-space: break-spaces;
              width: 200px;
            }
            .edit {
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
            }
          }
        }
        &.active {
          button.step-trigger {
            span.bs-stepper-circle {
              background-color: $blue-color;
              box-shadow: rgba(42, 50, 255, 0.35) 0px 0px 0px 7px;
              border: none;
            }
            .bs-stepper-label {
              .title {
                color: $dark-color;
              }
              .desc {
                color: $dark-gray-color;
              }
            }
          }
        }
        &.completed {
          button.step-trigger {
            span.bs-stepper-circle {
              background-color: $blue-color;
              border: none;
            }
          }
          & + .line {
            background-color: $blue-color;
          }
        }
      }
      div.line {
        margin-left: 11px;
        flex-basis: 55px;
      }
    }
    .bs-stepper-content {
      width: 100%;
      padding: 0 20px;
      .content {
        position: relative;
        height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
        scrollbar-width: none; /* Hide scrollbar for Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        &:not(.active) {
          display: none;
        }
        .bs-footer {
          margin: 30px 0 0 0;
          position: sticky;
          bottom: 0;
          background-color: $white-color;
        }
      }
    }
  }

  @media (max-width: 640px) {
    div.bs-stepper.vertical {
      display: block;
      .bs-stepper-header {
        min-width: auto;
        .step {
          button.step-trigger {
            display: block;
            span.bs-stepper-circle {
              margin: 0 25px 0 5px;
            }
          }
        }
      }
      .bs-stepper-content {
        margin-top: 40px;
      }
    }
  }
}

html[dir="rtl"] {
  .bs-stepper {
    .bs-stepper-header {
      .step {
        button.step-trigger {
          span.bs-stepper-circle {
            margin: 0 5px 0 25px;
          }
          span.bs-stepper-label {
            .edit {
              top: 0;
              left: 0;
              right: unset;
            }
          }
        }
      }
      div.line {
        margin-right: 11px;
        margin-left: auto;
      }
    }
  }

  @media (max-width: 640px) {
    div.bs-stepper.vertical {
      .bs-stepper-header {
        .step {
          button.step-trigger {
            span.bs-stepper-circle {
              margin: 0 5px 0 25px;
            }
          }
        }
      }
    }
  }
}
