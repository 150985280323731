.dashboard-tabs {
  .nav-item {
    .nav-link {
      color: $dark-gray-color;
      font-weight: 700;
      cursor: pointer;
      &.active {
        color: $dark-color;
        border: none;
        border-bottom: 2px solid $dark-color;
        &:hover,
        &:focus {
          border-bottom: 2px solid $dark-color;
        }
      }
      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
  }
}
