// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.hero-section {
  padding: 1rem 0;
  .section-bg {
    background-image: url('../../images/banner-bg.gif');
    min-height: 65vh;
    background-size: cover;
    background-position: center center;
    border-radius: 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4rem;
    .content {
      width: 100%;
      h1 {
        font-size: 3.6rem;
      }
      .white-bg {
        padding: 25px;
        border-radius: 5px;
        background: linear-gradient(94deg,rgba($white, 0.2) 22%, rgba($white, 0.3) 99%);
        backdrop-filter: blur(3px);
        box-shadow: 0px 0px 0px 10px rgba($white, 0.3);
      }
    }
  }
}

.activities-section {
  padding: 2rem 0 5rem 0;
  .activity-card {
    position: relative;
    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 10px;
    }
    .overlay {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      top: 0;
      height: 250px;
      width: 100%;
      background-color: rgba($darkBlue, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
  }
}

@media (max-width: 767px) {
  .hero-section {
    .section-bg {
      padding: 1.5rem;
      .content {
        h1 {
          font-size: 2rem;
        }
      }
    }
  }
}