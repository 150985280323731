.search-section {
  padding: 1rem 0;
  .section-bg {
    min-height: 65vh;
    background-size: cover;
    background-position: center center;
    border-radius: 25px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4rem;
    .search-box {
      border-radius: 4px;
      width: 470px;
      padding: 35px;
      background-color: rgba($darkBlue, 0.8);
    }
  }
}

.reviewer-card {
  .user-avatar {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
  }
}