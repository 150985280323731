.custom-radio {
  margin: 0;

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $blue-color;
    border-color: $blue-color;
  }
  .custom-control-label { 
    &:focus {
      outline: 0;
    }
  }
  &.fw-bold {
    .custom-control-label {
      padding-top: 1px;
    }
  }
}
